import PropTypes from "prop-types"
import React from "react"

import logo from "../images/logo1.svg";
import facebookIcon from "../images/social-facebook.svg";
import twitterIcon from "../images/social-twitter.svg";
import instagramIcon from "../images/social-instagram.svg";
import linkedInIcon from "../images/social-linkedin.svg";


const Footer = ({ siteTitle }) => (
    <div className="footer">
        <div className="layout-width">
            <div className="col2 footerL">
                <a href="https://cherryhillprograms.com/" rel="nofollow" target="_blank">
                    <img src={logo} alt="Cherry Hill Programs logo" />
                </a>
                <p className="copyright">© Copyright 2019-2020 Cherry Hill Programs, Inc—All Rights Reserved.</p>
            </div>
            <div className="col2 footerR hastooltip">
                <p>Delivering a Magical Experience. Every time.</p>
                <ul className="legal">
                    <li><a target="_blank" rel="nofollow" href="https://www.cherryhillprograms.com/privacy-policy">Privacy Policy</a></li>
                    <li><a target="_blank" rel="nofollow" href="https://www.cherryhillprograms.com/privacy-policy">Terms & Conditions</a></li>
                </ul>
                <ul className="socialList m-social-share">
                    <li><a target="_blank" rel="nofollow" href="https://www.facebook.com/CherryHillPrograms" ><img src={facebookIcon} alt="facebook link" /></a></li>
                    <li><a target="_blank" rel="nofollow" href="https://twitter.com/cherryhillprgms?s=11" ><img src={twitterIcon} alt="twitter link" /></a></li>
                    <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/cherryhillprograms/" ><img src={instagramIcon} alt="instagram link" /></a></li>
                    <li><a target="_blank" rel="nofollow" href="https://www.linkedin.com/company/cherryhillprograms/" ><img src={linkedInIcon} alt="linkedin link" /></a></li>
                </ul>
                <input type="text" defaultValue="" id="shareURL" className="copyInput hide2" />
                <div id="tooltip" className="">Link Copied</div>
            </div>
        </div>
    </div>
    
)
Footer.propTypes = { siteTitle: PropTypes.string, }
Footer.defaultProps = { siteTitle: ``, }

export default Footer
